<template>
  <a-drawer width="55%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">
      <a-form-model-item :label="$t('线下店铺套餐内容.备注')" prop="remark">
        <a-input v-model="form.remark" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('线下店铺套餐内容.备注')"/>
      </a-form-model-item>

<!--      <a-form-model-item :label="this.$t('线下店铺套餐内容.套餐logo')" prop="logo">
        <a-upload
          name="logo"
          listType="picture-card"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgUpload">
          <img
            v-if="form.logo"
            :src="form.logo"
            alt="logo"
            style="height: 200px; width: 200px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
          </div>
        </a-upload>
        <span>推荐尺寸 60 x 60</span>
      </a-form-model-item>

      <a-form-model-item :label="$t('线下店铺套餐内容.有效期')" prop="validity">
        <a-input v-model="form.validity" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('线下店铺套餐内容.有效期')"/>
      </a-form-model-item>

      <a-form-model-item :label="$t('线下店铺套餐内容.使用时间')" prop="usetime">
        <a-input v-model="form.usetime" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('线下店铺套餐内容.使用时间')"/>
      </a-form-model-item>

      <a-form-model-item :label="$t('线下店铺套餐内容.使用规则')" prop="rule">
        <a-input v-model="form.rule" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('线下店铺套餐内容.使用规则')"/>
      </a-form-model-item>-->

      <a-form-model-item :label="$t('线下店铺套餐内容.是否需要预约')" prop="reservation">
        <a-select :placeholder="$t('通用.输入.请选择')+$t('商品.是否预约')" style="width: 100%" v-model="form.reservation"
                  allowClear>
          <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type"
                           :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-item label="预约时间" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" class="margin-bottom-10">
        <div class="margin-bottom-10">
          <a-button type="primary" @click='addTime'>
            添加时间段
          </a-button>
        </div>
        <a-table :data-source="form.siteTimeSlotList" :columns="timeColumns" rowKey="id" :pagination="false" bordered>
          <div slot="startTimePeriod" slot-scope="text, record">
            <a-time-picker style="width: 100%" v-model="'2022-12-21 '+record.startTime"
                           @change="(date,dateStr) =>{startTimePeriodChange(date,dateStr,record)}" format="HH:mm"
                           allow-clear/>
          </div>
          <div slot="endTimePeriod" slot-scope="text, record">
            <a-time-picker style="width: 100%" v-model="'2022-12-21 '+record.endTime"
                           @change="(date,dateStr) =>{endTimePeriodChange(date,dateStr,record)}" format="HH:mm"
                           allow-clear/>
          </div>

          <div slot="price" slot-scope="text, record">
            <a-input v-model="record.price" :maxLength="9" @change="limitPriceAmount(record)" placeholder="请输入金额"/>
          </div>
          <div slot="action" slot-scope="text, record">
            <a-popconfirm title="确定要删除此时段吗？" ok-text="确定" cancel-text="取消" @confirm="delTime(record)">
              <a href="javascript:void(0)" style="color:red;">删除</a>
            </a-popconfirm>
          </div>
        </a-table>
      </a-form-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl"/>
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('通用.按钮.保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('通用.按钮.取消') }}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getSys, addSys, updateSys} from '@/api/thali/sys'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {},
  components: {
    CustomDictTag,
  },
  data() {
    return {
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      timeColumns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index'
        },
        {
          title: '开始时间',
          dataIndex: 'startTime',
          key: 'startTime',
          scopedSlots: {customRender: 'startTimePeriod'}
        },
        {
          title: '结束时间',
          dataIndex: 'endTime',
          key: 'endTime',
          scopedSlots: {customRender: 'endTimePeriod'}
        },
        /*{
          title: '金额',
          dataIndex: 'price',
          key: 'price',
          scopedSlots: {customRender: 'price'}
        }, */
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          slots: {title: 'addbtn', customRender: 'name'},
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 表单参数
      form: {
        id: null,
        siteTimeSlotList: [],
        sysId: null,

        logo: null,

        validity: null,

        usetime: null,

        rule: null,

        reservation: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        logo: [
          {required: true, message: this.$t('线下店铺套餐内容.套餐logo') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        remark: [
          {required: true, message: this.$t('线下店铺套餐内容.备注') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        validity: [
          {required: true, message: this.$t('线下店铺套餐内容.有效期') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        usetime: [
          {required: true, message: this.$t('线下店铺套餐内容.使用时间') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        siteTimeSlot: [
          { required: true, message: '场次 [{\"id\":\"23044\",\"startTime\":\"08:00\",\"endTime\":\"09:00\"}]不能为空', trigger: 'blur' }
        ],
        rule: [
          {required: true, message: this.$t('线下店铺套餐内容.使用规则') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        reservation: [
          {required: true, message: this.$t('线下店铺套餐内容.是否需要预约 0：否 1：是') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
      this.reset()
    },
    addTime() {
      console.log(1111111, this.form.siteTimeSlotList)
      const index = this.form.siteTimeSlotList.length + 1;
      const newData = {
        index: index,
        startTime: "00:00",
        endTime: "00:00",
        price: null
      };
      this.form.siteTimeSlotList.push(newData);
      this.count = index;
    },
    /**
     * 删除等级
     */
    delTime(info) {
      this.form.siteTimeSlotList = this.form.siteTimeSlotList.filter(item => item.index !== info.index);
    },
    limitPreAmount(value) {
      // console.log(i);
      let val=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1');
      if(val==null||val==undefined||val==''){
        val=0
      }
      this.form.preAmount=val
    },
    limitPriceAmount(record) {
      // console.log(i);
      let val=record.price.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1');
      if(val==null||val==undefined||val==''){
        val=0
      }
      if (val > 21474836) {
        val = 21474836
      }
      record.price = val
    },
    startTimeChange(date, dateString){
      this.form.startTime = dateString
    },
    endTimeChange(date, dateString){
      this.form.endTime = dateString
    },

    startTimePeriodChange(date, dateString,record){
      record.startTime = dateString
    },
    endTimePeriodChange(date, dateString, record) {
      record.endTime = dateString
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        siteTimeSlotList: [],
        sysId: null,
        logo: null,
        validity: null,
        usetime: null,
        rule: null,
        reservation: null,
        createTime: null,
        remark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSys({"id": id}).then(response => {
        this.form = response.data
        let idx = 1;
        this.form.siteTimeSlotList.forEach(e=>{
          e.index= idx;
          idx++;
        })
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      } else {
        this.handleImagePreview(file);
      }
    },

    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'cate'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'logo', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // oss 上传结束
    //多图上传开始
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    // 上传详情覆盖默认的上传行为
    coverImgCaseUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'advert'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.imgCaseList.push({status: 'done', url: res, uid: this.getUidRandom(), name: res})
          console.log(_this.imgCaseList)
          this.form.imgCaseList = []
          _this.imgCaseList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              this.form.imgCaseList.push(e.url);
            }
          })
          _this.$message.success('上传成功')
        })
      })
    },
    // 删除图片
    removeImgCaseFile(file) {
      var fileList = []
      this.imgCaseList.forEach(e => {
        if (e.url !== file.url) {
          fileList.push(e)
        }
      })
      var imgCaseList = []
      this.imgCaseList = fileList
      fileList.forEach(e => {
        imgCaseList.push(e.url)
      })
      this.form.imgCaseList = imgCaseList
    },
    //多图上传结束
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateSys(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addSys(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
